/* Global styles for the date picker and its dropdown */
:global(.ant-picker-dropdown),
:global(.ant-picker-panel) {
    background: var(--color-primary) !important;
}

/* Global text color for all picker elements */
:global(.ant-picker-cell),
:global(.ant-picker-content th),
:global(.ant-picker-cell-in-view),
:global(.ant-picker-header),
:global(.ant-picker-header button),
:global(.ant-picker-clear),
:global(.ant-picker-suffix) {
    color: var(--color-secondary-dark) !important;
}

.datePicker {
    background: var(--color-primary) !important;
    color: var(--color-secondary-dark) !important;
    display: block;
    padding: .6rem 1rem;
    width: 20rem;
    border: 2px solid var(--color-tertiary) !important;
    border-radius: 5px;
    cursor: pointer;
    font-family: inherit;

    /* Input styles */
    /* :global(.ant-picker-input > input),
    :global(.ant-picker-input > input::placeholder) {
        color: var(--color-secondary-dark) !important;
        font-size: 1.5rem;
        font-weight: 500;
    } */
}

:global(.ant-picker-input > input),
:global(.ant-picker-input > input::placeholder) {
    color: var(--color-secondary-dark) !important;
    font-size: 1.5rem;
    font-weight: 500;
}

/* Header bottom border */
:global(.ant-picker-header) {
    border-bottom: 1px solid #25253d !important;
}

/* Hover and selection styles */
:global(.ant-picker-cell:hover .ant-picker-cell-inner) {
    background: #353456 !important;
}

:global(.ant-picker-cell-selected .ant-picker-cell-inner) {
    background: var(--color-tertiary) !important;
}