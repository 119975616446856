.mainContent {
    background-color: #25253d;
    flex: 1;
    padding: 10px;
    width: 100%;
}

.walletsContent {
    padding: 3rem 4rem;
}

.webAppHeading {
    color: #c7c4e9;
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.2;
}

.dcrHeading {
    margin: 3rem 0 0 4rem;
}

.mb2 {
    color: #9e9ad1;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0;
}

.rowBg2 {
    padding: 1rem;
    background-color: #2b2c47;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.amount {
    font-size: clamp(1.4rem, 1.6vw, 1.6rem);
    font-weight: 600;
    color: #36abf9;
    /* font-family: sans-serif; */
    font-weight: 400;
    font-style: normal;
}

.walletsTags {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.walletsTags h4 {
    font-weight: 500;
    color: #fff;
    font-size: 1.2rem;
}

.walletsTagReContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.walletsRecharge {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #fff;
}

.walletsRecharge svg {
    color: #07bc0c;
    font-size: 2rem;
}

.walletsRecharge h4 {
    font-weight: 500;
    font-size: clamp(1.2rem, 1.4vw, 1.4rem);
}

.yourTrans {
    display: flex;
    flex-direction: column;
    padding: 10px;
    
}

.recentWallets {
    color: #9e9ad1;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 1rem;
}

.transData {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    color: #c7c4e9;
    background-color: #353456;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 1rem;
    position: relative;
}

.redStrips {
    background-color: #ff0000;
    width: 5px;
}

.debCre {
    color: #ff0000;
}
.debCgr{
    color: #07bc0c;
}

.transDataData {
    display: flex;
    
    flex-wrap: wrap;
    gap: 3rem;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.amountContainer {
    display: flex;
    height: fit-content;
    flex-direction: column;
    padding: 0px 15px;
    padding-top: 12px;
    padding-bottom: 0px;
    border: 1px solid #36abf9;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
}

.addFontWeight {
    font-weight: 600;
}

.fixSpaceDynamicValue {
    width: 75px;
}

/* .blueImg {
    filter: brightness(0) saturate(100%) invert(17%) sepia(91%) saturate(1677%) hue-rotate(201deg) brightness(94%) contrast(94%);
} */

.blueImg{
    width: 3rem;
    height: auto;
   color: #36ABF9;
    /* fill: red;  */
    /* margin-top: 0.5rem;
    /* margin-right: 1rem; */
    /* align-self: flex-end; */ 
    position: absolute;
    top: 10px;  
    right: 30px;
}

.ammount {
    font-size: clamp(1.4rem, 1.6vw, 1.6rem);
    font-weight: 600;
    color: #36abf9;
    font-family: "Electrolize", serif;
    font-style: normal;
}
.recentWallets {
    color: #9e9ad1;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 1rem;
}

@media (max-width: 1024px) {
    .transDataData {
        gap: 1.5rem;
    }
    .walletsContent {
        padding: 2rem;
    }

    .dcrHeading {
        margin: 2rem 0 0 2rem;
    }

    .walletsTags {
        gap: 1.5rem;
    }

    .walletsTagReContainer {
        align-items: flex-start;
    }

    .rowBg2 {
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 800px) {
    .transDataData {
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
    }

    .walletsContent {
        padding: 1.5rem;
    }

    .dcrHeading {
        margin: 1.5rem 0 0 1.5rem;
    }

    .walletsTags {
        flex-direction: column;
        gap: 1rem;
    }

    .walletsRecharge {
        text-align: center;
    }

    .rowBg2 {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .walletsContent {
        padding: 1rem;
    }

    .dcrHeading {
        margin: 1rem 0 0 1rem;
    }

    .webAppHeading {
        font-size: 1.3rem;
    }

    .walletsTags h4,
    .walletsRecharge h4 {
        font-size: 1.2rem;
    }

    .walletsRecharge svg {
        font-size: 1.7rem;
    }

    .walletsRecharge {
        text-align: center;
    }

    .rowBg2 {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}